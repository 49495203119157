import {json, type LoaderFunctionArgs} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';

import {pageLoader as baseLoader} from '@/utils/server/loader/pageLoader';
import {UrlUtils} from '@/utils/UrlUtils';
import PageLayout from '@/components/shared/Page/PageLayout';
import HeroFloatingImage from '@/components/base/sections/Hero/HeroFloatingImage';
import Section from '@/components/base/layouts/Section/Section';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import Card from '@/components/base/modules/Card/Card';
import SideBySideList from '@/components/base/sections/SideBySideList/SideBySideList';
import SideBySide from '@/components/base/modules/SideBySide/SideBySide';
import Typography from '@/components/base/elements/Typography/Typography';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import FullWidthCard from '@/components/base/modules/FullWidthCard/FullWidthCard';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Faq from '@/components/base/modules/Faq/Faq';
import Disclaimers from '@/components/shared/Disclaimers/Disclaimers';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum} from '@/enums';
import {useTranslations} from '@/hooks/useTranslations';

export const loader = async (args: LoaderFunctionArgs) => {
  const settingsJson = await args.context.getPageData('settings.json');
  const settings = JSON.parse(settingsJson ?? '{}');

  const baseData = await baseLoader(args);
  const urlUtils = new UrlUtils(args.context.site);

  const localizedLinks = {
    heroFloatingImageHref: urlUtils.getUrl(
      settings.links.heroFloatingImage.href,
      'admin',
    ),
    sideBySideSectionHref: urlUtils.getUrl(
      settings.links.sideBySideSection.href,
      'site',
    ),
    sideBySideSectionHref2: urlUtils.getUrl(
      settings.links.sideBySideSection2.href,
      'help',
    ),
    sideBySideSectionHref3: settings.links.sideBySideSection3.href,
    fullWidthCardHref: urlUtils.getUrl(
      settings.links.fullWidthCard.href,
      'admin',
    ),
    resourceSection: settings.links.resourceSection.map(
      (link: any, idx: number) => {
        switch (idx) {
          case 0:
            return urlUtils.getUrl(link, 'help');
          case 1:
            return urlUtils.getUrl(link, 'blog');
          case 2:
            return urlUtils.getUrl(link, 'site');
        }

        return '';
      },
    ),
  };

  return json({
    ...baseData,
    localizedLinks,
  });
};

export default function ShopPay() {
  const {t, images, site, localeImage} = useTranslations();
  const {localizedLinks} = useLoaderData<typeof loader>();
  // The following variable is used to ensure particular components are only rendered on the US Shop Pay page.
  const withinTheUS = site.countryCode === 'US';

  const pageContent = {
    heroFloatingImage: t('heroFloatingImage'),
    cardsSection: t('cardsSection'),
    sideBySideList: t('sideBySideList'),
    sideBySideSection: t('sideBySideSection'),
    sideBySideSection2: t('sideBySideSection2'),
    sideBySideSection3: t('sideBySideSection3'),
    fullWidthCard: t('fullWidthCard'),
    resourceSection: t('resourceSection'),
    faq: t('faq'),
  };

  const localizedDisclaimers = withinTheUS
    ? t('disclaimerSection.disclaimerList')
    : [t('disclaimerSection.disclaimerList.0')];

  return (
    <PageLayout footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}>
      <HeroFloatingImage
        headingGroupProps={{
          ...pageContent.heroFloatingImage.headingGroupProps,
          kickerAs: 'h1',
          headingAs: 'h2',
        }}
        ctas={{
          ...t('heroFloatingImage.ctas'),
          buttons: pageContent.heroFloatingImage.ctas.buttons.map(
            (button: any) => ({
              text: button.text,
              href: localizedLinks.heroFloatingImageHref,
            }),
          ),
        }}
        images={[
          {
            alt: pageContent.heroFloatingImage.images[0].alt,
            src: localeImage(images.heroFloatingImage.image.src),
            srcSet: localeImage(images.heroFloatingImage.image.srcSet),
          },
        ]}
        className="bg-shop-pay-hero"
      />
      <Section topSpacing="2xl" bottomSpacing="2xl" className="bg-[#e6ede2]">
        <CardGrid withContainer={true} numberOfColumns={3}>
          {pageContent.cardsSection.cards.map((card: any, idx: number) => (
            <Card
              {...card}
              headingGroup={{
                ...card.headingGroup,
                headingAs: 'h3',
              }}
              size="small"
              key={`${idx}-${card.headingGroup.headingHtml}`}
              image={{
                src: images.cardsSection.cards.en[idx],
                width: '64px',
                height: '64px',
              }}
              className="py-8"
            />
          ))}
        </CardGrid>
      </Section>
      <SideBySideList
        {...pageContent.sideBySideList}
        sideBySideProps={{
          image: {
            src: localeImage(images.sideBySideList.sideBySideProps.image.src),
            srcSet: localeImage(
              images.sideBySideList.sideBySideProps.image.srcSet,
            ),
            alt: pageContent.sideBySideList.sideBySideProps.image.alt,
            loading: 'lazy',
          },
          visualPosition: withinTheUS ? 'left' : 'right',
        }}
        className="bg-shop-pay-sideBySideList"
      />
      {withinTheUS ? (
        <Section topSpacing="2xl" bottomSpacing="2xl" className="bg-[#f0edff]">
          <SideBySide
            verticalAlign="center"
            visualPosition="right"
            image={{
              src: localeImage(images.sideBySideSection.image.src),
              srcSet: localeImage(images.sideBySideSection.image.srcSet),
              alt: pageContent.sideBySideSection.image.alt,
              loading: 'lazy',
            }}
          >
            <Typography as="h3" size="t3">
              {pageContent.sideBySideSection.headingHtml}
            </Typography>
            <Typography as="p" size="body-base">
              {pageContent.sideBySideSection.subheadHtml}
            </Typography>
            <ButtonGroup
              buttons={[
                {
                  text: pageContent.sideBySideSection.buttonGroup.buttons[0]
                    .text,
                  href: localizedLinks.sideBySideSectionHref,
                },
              ]}
            />
          </SideBySide>
        </Section>
      ) : null}
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="bg-shop-pay-checkout-links"
      >
        <SideBySide
          verticalAlign="center"
          image={{
            src: localeImage(images.sideBySideSection2.image.src),
            srcSet: localeImage(images.sideBySideSection2.image.srcSet),
            alt: pageContent.sideBySideSection2.image.alt,
            loading: 'lazy',
          }}
        >
          <Typography as="h3" size="t3">
            {pageContent.sideBySideSection2.headingHtml}
          </Typography>
          <Typography as="p" size="body-base">
            {pageContent.sideBySideSection2.subheadHtml}
          </Typography>
          <ButtonGroup
            buttons={[
              {
                text: pageContent.sideBySideSection2.buttonGroup.buttons[0]
                  .text,
                href: localizedLinks.sideBySideSectionHref2,
              },
            ]}
          />
        </SideBySide>
      </Section>
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="bg-shop-pay-carbon-footprint"
      >
        <SideBySide
          verticalAlign="center"
          visualPosition="right"
          image={{
            src:
              site.locale === 'en'
                ? images.sideBySideSection3.image.src.us
                : images.sideBySideSection3.image.src.nonUs,
            srcSet:
              site.locale === 'en'
                ? images.sideBySideSection3.image.srcSet.us
                : images.sideBySideSection3.image.srcSet.nonUs,
            alt: pageContent.sideBySideSection3.image.alt,
            loading: 'lazy',
          }}
        >
          <Typography as="h3" size="t3">
            {pageContent.sideBySideSection3.headingHtml}
          </Typography>
          <Typography as="p" size="body-base">
            {pageContent.sideBySideSection3.subheadHtml}
          </Typography>
          <ButtonGroup
            buttons={[
              {
                text: pageContent.sideBySideSection3.buttonGroup.buttons[0]
                  .text,
                href: localizedLinks.sideBySideSectionHref3,
              },
            ]}
          />
        </SideBySide>
      </Section>
      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="bg-separate-middle from-white to-black"
      >
        <FullWidthCard
          image={{
            src:
              site.locale === 'en'
                ? images.fullWidthCard.image.src.us
                : images.fullWidthCard.image.src.nonUs,
            srcSet:
              site.locale === 'en'
                ? images.fullWidthCard.image.srcSet.us
                : images.fullWidthCard.image.srcSet.nonUs,

            alt: pageContent.fullWidthCard.image.alt,
            loading: 'lazy',
          }}
          mode="light"
          visualPosition="left"
          className="bg-pos-warm-gray"
        >
          <Typography
            as="h3"
            size="t7"
            className="text-eyebrow-light-text uppercase font-bold leading-none"
          >
            {pageContent.fullWidthCard.kicker}
          </Typography>
          <Typography as="p" size="t3" mode="light" className="py-4 md:pt-0">
            {pageContent.fullWidthCard.headingHtml}
          </Typography>
          <ButtonGroup
            buttons={pageContent.fullWidthCard.buttonGroup.buttons.map(
              (button: any) => ({
                text: button.text,
                href: localizedLinks.fullWidthCardHref,
              }),
            )}
          />
        </FullWidthCard>
      </Section>
      <Section topSpacing="2xl" bottomSpacing="2xl" className="bg-black">
        <SectionHeader
          {...pageContent.resourceSection.sectionHeader}
          mode="dark"
        />
        <CardGrid withContainer={true} numberOfColumns={3}>
          {pageContent.resourceSection.cards.map((card: any, idx: number) => (
            <Card
              {...card}
              headingGroup={{
                ...card.headingGroup,
                headingAs: 'h3',
              }}
              link={{
                text: card.link.text,
                href: localizedLinks.resourceSection[idx],
              }}
              key={`${idx}-${card.headingGroup.headingHtml}`}
              type="outline"
              size="small"
              mode="dark"
              className="py-8"
            />
          ))}
        </CardGrid>
      </Section>
      {pageContent.faq.accordionItems && (
        <Section topSpacing="2xl" bottomSpacing="2xl" mode="dark">
          <SectionHeader {...pageContent.faq.sectionHeader} mode="dark" />
          <Faq
            accordionItems={pageContent.faq.accordionItems.map(
              (item: any, idx: number) => ({
                ...item,
                id: `${item.heading.replace(' ', '')}-${idx}`,
              }),
            )}
            mode="dark"
          />
        </Section>
      )}
      {localizedDisclaimers && (
        <Disclaimers disclaimers={localizedDisclaimers} />
      )}
    </PageLayout>
  );
}
